import React from 'react';
import LoginPage from './main/components/LoginPage';
// import SignupPage from './main/components/SignupPage';
import bg_image from './main/assets/bg-image.jpg'
import 'antd/dist/antd.css'
import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import ErrorPage from './main/components/ErrorPage';
import { getUser } from './main/commonfn';
import MainLayout from './main/layout/MainLayout';
import { notification } from 'antd';
import HomePage from './main/components/HomePage';
import IdolPage from './main/components/IdolPage';
import PoojasPage from './main/components/PoojasPage';
import PoojasOrders from './main/components/PoojasOrders';
import IdolOrders from './main/components/IdolOrders';

const App = () => {
  const [api, contextHolder] = notification.useNotification();

  const containerStyle = {
    // backgroundImage: `url(${bg_image})`, // Change this to the path of your background image
    minHeight: '100vh',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  };
  const userLoader = async () => {
    const user = await getUser();
    if (!user) {
      return redirect("/login");
    }
    return null
  }
  const openLoginNotification = (login,errMessage) => {
    if(login){
      const user = getUser();
      api['success']({
        message: `Logged successfully`,
        description:
          `Hello, ${user.admin_name}!`,
          duration: 3,
      });
    }else{
      api['error']({
        message: `Login failed`,
        description:
          `${errMessage}`,
          duration: 3,
      });
    }
    
  };
  const openRegisterNotification = () => {
      api['success']({
        message: `Registered successfully`,
        description:
          `Make your login here! 😊`,
          duration: 3,
      });
    
  };
  const router = createBrowserRouter([
    {
      errorElement: <ErrorPage />,

      children: [
        {
          path: "/",
          errorElement: <ErrorPage />,
          element: <Navigate to="/home" />,
          loader: () => userLoader(),
        },
        {
          path: "/login",
          element: <LoginPage openLoginNotification={openLoginNotification}/>,
          errorElement: <ErrorPage />,
        },
        // {
        //   path: "/register",
        //   element: <SignupPage openRegisterNotification={openRegisterNotification} />,
        //   errorElement: <ErrorPage />,
        // },
        {
          path: "/",
          element: <MainLayout />,
          errorElement: <ErrorPage />,
          loader: () => userLoader(),
          children: [
            // {
            //   path:"/",
            //   errorElement: <ErrorPage />, 
            //   element: <Navigate to="/app/poojas" />,
            // },
            {
              path: "/home",
              element: <HomePage/>,
              errorElement: <ErrorPage />,
            },
            {
              path: "/idol",
              element: <IdolPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poojas",
              element: <PoojasPage />,
              errorElement: <ErrorPage />,
            }, 
            {
              path: "/poojas-orders",
              element: <PoojasOrders />,
              errorElement: <ErrorPage />,
            },{
              path: "/idol-orders",
              element: <IdolOrders />,
              errorElement: <ErrorPage />,
            }
          ]
        }
      ]
    }
  ]);

  return (
    <>
      {contextHolder}
      <div style={containerStyle}>
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;
