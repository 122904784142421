import React from 'react'
import { Outlet } from 'react-router-dom'
import FooterSec from './FooterSec'
import { Layout, Flex } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { userLogout } from '../commonfn';

const { Header, Footer, Content } = Layout;

const headerStyle = {
  textAlign: 'center',
  color: '#fff',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
  backgroundColor: '#4096ff',
};
const contentStyle = {
  textAlign: 'center',
  minHeight: '100vh',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#0958d9',
};
const siderStyle = {
  textAlign: 'center',
  lineHeight: '120px',
  minHeight: '100vh',
  color: '#fff',
  backgroundColor: '#1677ff',
};
const footerStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#4096ff',
};
const layoutStyle = {
  borderRadius: 8,
  // overflow: 'hidden',
  // width: 'calc(100% - 8px)',
  // maxWidth: 'calc(100% - 8px)',
};

export default function MainLayout() {
    
  return (
    <div className=' main-layout'>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">GOVIVAH ADMIN</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item ">
          <a class="nav-link active " aria-current="page" href="/home">Home</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" href="/poojas">Poojas</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" href="/poojas-orders">Poojas-Orders</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/idol">Idol</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/idol-orders">Idol-Orders</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/idol-orders">Users</a>
        </li>
      </ul>
    </div>
    <div className='d-flex align-itmes-end'>
    <a class="nav-link" href="/Login" onClick={userLogout}>Logout</a>
    </div>
  </div>
</nav>
<div className='px-md-3 px-1 py-md-2 py-0'>
<Outlet/>
</div>
    </div>
  )
}
