import { Table } from 'antd';
import React, { useState } from 'react'

export default function PoojasOrders() {
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: 150,
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Action 2',
      width: 90,
      render: () => <a>action</a>,
    },
  ];
  return (
    <div> <Table
    columns={columns}
    dataSource={data}
    pagination={{
      pageSize: 50,
    }}
    scroll={{
      y: 240,
    }}
  /></div>
  )
}
