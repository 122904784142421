import React from 'react'
import main_logo from "../assets/main_logo.jpeg";


export default function HomePage() {
  return (
    <>
    <div className=' p-3 d-flex justify-content-center'>
<h1 className='fw-bolder'>Welcome to Admin Panel</h1>
    </div>
<div className=' p-3 d-flex justify-content-center '>
  <img className='shadow-lg rounded' width='550' src={main_logo}/>
</div>
    </>
  )
}
