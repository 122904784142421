import { Button, Checkbox, Form, Image, Input, InputNumber, Modal, Select, Table, Tag, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../axiosInstance'
import { useNavigate } from 'react-router-dom'
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const convertBase64String = (imageFile) => {

  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(imageFile);
    console.log(fileReader)
    fileReader.addEventListener('load', () => {
      if (fileReader.result) {
        resolve(fileReader.result);
      }
      else {
        reject('Error Occurred');
      }
    })
  });
};

export function IdolCat() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState([])
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [addImage, setAddImage] = useState(null)
  const [editId,setEditId] = useState(null)

  const onFinish = async (values) => {
    values.cat_image = addImage
    values.is_active = values.is_active ? 1 : 0
    let endpoint = ''
    if(editId){
      endpoint = '/admin/idol-cat/edit'
      values.id=editId
    }else{
     endpoint= '/admin/idol-cat/add'
    }

    axiosInstance.post(endpoint, values).then((res) => {
      console.log(res);
      if (res.status == 200) {
        getCat()
        setIsAddModalOpen(false)
        form.resetFields()
        setAddImage(null)
        setEditId(null)
      }
    }).catch((err) => {
      console.log(err)
      alert('API Error')
    })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    alert('Error')
  };

  useEffect(() => {
    getCat()
  }, [])

  const getCat = () => {
    axiosInstance.get('/admin/idol-cat/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      setData(res.data.data)
    }).catch((err) => console.log(err))
  }
  const imageUpload = async (e) => {
    console.log(e);
    let base64Image = convertBase64String(e.target.files[0]);
    base64Image.then(response => setAddImage(response))

  };


  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: 50,
    },
    {
      title: 'Category Name',
      dataIndex: 'cat_name',
    },
    {
      title: 'Image',
      dataIndex: 'cat_image',
      width: 150,
      render: (e) => <Image src={e} />,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      width: 100,
      render: (e) => {
        if (e == 1) {
          return (<Tag color="success">Active</Tag>)
        }
        return (<Tag color="error">Inactive</Tag>)
      }
    },
    {
      title: 'Action',
      width: 150,
      render: (_,row) => <div className='d-flex justify-content-evenly'><Button type="primary" style={{ lineHeight: 0 }} shape="round" onClick={()=>onEdit(row)}><EditOutlined /></Button><Button shape="round" type="primary" danger style={{ lineHeight: 0 }} onClick={()=>onDelete(row.id)}><DeleteOutlined /></Button></div>,
    },
  ];

  const onEdit=(row)=>{
    form.resetFields()
    console.log(row);
    setEditId(row.id)
    setAddImage(row.cat_image)
    setIsAddModalOpen(true)
    form.setFieldsValue({...row})
  }

  const onDelete=()=>{

  }

  return (
    <div>
      <Button onClick={() => {setEditId(null);setIsAddModalOpen(true)}} className='d-flex align-items-center justify-content-center' shape="round" type="primary" block>
        <PlusCircleOutlined />  Add Category
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 10,
        }}
      // scroll={{
      //     y: '400px',
      // }}
      />
      <Modal title="Add Category" maskClosable={false} open={isAddModalOpen} onCancel={() => { setIsAddModalOpen(false); form.resetFields(); setAddImage(null);setEditId(null) }} footer={null}>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Category Name"
            name="cat_name"
            rules={[
              {
                required: true,
                message: 'Please input Category Name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Image"
            name="cat_image"
            rules={[
              {
                message: '',
              },
              {
                validator: (rule, value, callback) => {
                  if (addImage == null) {
                    callback('Please input image!');
                  } else {
                    callback();
                  }
                }
              }
            ]}
          >
            {/* <Upload listType="picture-card" action={imageUpload}  maxCount={1}>
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          </Upload>  */}
            {addImage ? <Image
              width={100}
              src={addImage}
            /> : null}
            <input type="file" accept="image/*" onChange={imageUpload} />             </Form.Item>
          <Form.Item
            name="is_active"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Active</Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button onClick={() => { setIsAddModalOpen(false); form.resetFields();setEditId(null); setAddImage(null) }}>
              Cancel
            </Button>
            <Button className='ms-3' type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal></div>
  )
}
export function IdolList() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState([])
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [addImage, setAddImage] = useState(null)
  const [cat, setCat] = useState([])
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [viewData, setViewData] = useState(null)
  const [editId,setEditId] = useState(null)


  useEffect(() => {
    getList()
    getCat()
  }, [])

  const onFinish = async (values) => {
    values.idol_image = addImage
    values.is_active = values.is_active ? 1 : 0;
    let idol_detail = {
      depth: values.PD_depth || '',
      detail: values.PD_detail || '',
      height: values.PD_height || '',
      metal: values.PD_metal || '',
      weight: values.PD_weight || '',
      width: values.PD_width || ''
    }
    let params = {
      ...values,
      idol_detail: JSON.stringify(idol_detail)

    }
    let endpoint = ''
    if(editId){
      endpoint = '/admin/idol-list/edit'
      params.id=editId
    }else{
     endpoint= '/admin/idol-list/add'
    }

    console.log('Success:', params);
    axiosInstance.post(endpoint, params).then((res) => {
      if (res.status == 200) {
        getList()
        setIsAddModalOpen(false)
        form.resetFields()
        setAddImage(null)
        setEditId(null)
      }
    }).catch((err) => {console.log(err); alert('API Error')})
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    alert('Error')
  };


  const imageUpload = async (e) => {
    console.log(e);
    let base64Image = convertBase64String(e.target.files[0]);
    base64Image.then(response => setAddImage(response))

  };
  const getCat = () => {
    axiosInstance.get('/admin/idol-cat/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      setCat(res.data.data)
    }).catch((err) => console.log(err))
  }
  const getList = () => {
    axiosInstance.get('/admin/idol/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      setData(res.data.data)
    }).catch((err) => console.log(err))
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'idol_name',
      width: 230,
    },
    {
      title: 'Category',
      dataIndex: 'cat_name',
      width: 150,
    },
    {
      title: 'Image',
      dataIndex: 'idol_image',
      width: 150,
      render: (e) => <Image src={e} />,
    },
    {
      title: 'Price (₹.)',
      dataIndex: 'price',
      width: 190,
    },
    {
      title: 'Stocks',
      dataIndex: 'stock',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      width: 100,
      render: (e, row) => renderTag(e,row)
    },
    {
      title: 'Action',
      width: 150,
      render: (_,row) => <div className='d-flex justify-content-evenly'><Button type="primary" style={{ lineHeight: 0 }} shape="round" onClick={()=>onEdit(row)}><EditOutlined /></Button><Button shape="round" type="primary" danger style={{ lineHeight: 0 }} onClick={()=>onDelete(row)}><DeleteOutlined /></Button><Button onClick={()=>{setIsViewModalOpen(true);setViewData(row)}} style={{ lineHeight: 0 }} shape="round"><EyeOutlined /></Button></div>,
    },
  ];
const renderTag =(e,row)=>{ if (e == 1) {
  return (<div className='d-flex'>
    <Tag color="success">Active</Tag>
    {row.stock < 1 ? <Tag color="default">out of stock</Tag> : null}
  </div>)
}
return (<Tag color="error">Inactive</Tag>)}
  const onEdit=(row)=>{
    form.resetFields()
    console.log(row);
    setEditId(row.id)
    setAddImage(row.idol_image)
    setIsAddModalOpen(true)
    let detail = JSON.parse(row.idol_detail)
    form.setFieldsValue({...row,
      PD_depth: detail.depth || '',
      PD_detail: detail.detail || '',
      PD_height: detail.height || '',
      PD_metal: detail.metal || '',
      PD_weight: detail.weight || '',
      PD_width: detail.width || ''})
  }

  const onDelete=(row)=>{
    
    let text =`Do you want to Delete ${row.idol_name} IDOL`
    if(window.confirm(text) == true){
      axiosInstance.delete(`/admin/idol-list/delete/${row.id}`).then((res)=>{
        console.log(res);
        getList()
        alert('Deleted!')
      }).catch((err)=>{console.log(err);alert('API Error')})
    }
  }
  return (
    <div>
      <Button onClick={() => {setEditId(null);setIsAddModalOpen(true)}} className='d-flex align-items-center justify-content-center' shape="round" type="primary" block>
        <PlusCircleOutlined />  Add Product
      </Button><Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 10,
        }}
      // scroll={{
      //     y: 240,
      // }}
      />
      <Modal title="Add Product" width={900} open={isAddModalOpen} maskClosable={false} onCancel={() => { setIsAddModalOpen(false); form.resetFields();setEditId(null); setAddImage(null) }} footer={null}>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          style={{
            maxWidth: 900,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="idol_name"
            rules={[
              {
                required: true,
                message: 'Please input Idol Name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Category"
            name="idol_cat"
            rules={[
              {
                required: true,
                message: 'Please input Idol category',
              },
            ]}
          >
            <Select
            disabled={editId}
              placeholder="Select a option"
              allowClear
              onChange={(e) => form.setFieldsValue({ idol_cat: e })}
              value={form.getFieldValue('idol_cat')}
            >
              {cat.map((e) => {
                return (<Select.Option value={e.id}>
                  {e.cat_name}
                </Select.Option>)
              })}
            </Select>    </Form.Item>
          <Form.Item
            label="Image"
            name="idol_image"
            rules={[
              {
                message: '',
              },
              {
                validator: (rule, value, callback) => {
                  if (addImage == null) {
                    callback('Please input image!');
                  } else {
                    callback();
                  }
                }
              }
            ]}
          >
            {/* <Upload listType="picture-card" action={imageUpload}  maxCount={1}>
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          </Upload>  */}
            {addImage ? <Image
              width={100}
              src={addImage}
            /> : null}
            <input type="file" accept="image/*" onChange={imageUpload} />             </Form.Item>

          <Form.Item
            label="Description"
            name="idol_desc"
            rules={[
              {
                required: true,
                message: 'Please input Description!',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
            rules={[
              {
                required: true,
                message: 'Please input Price!',
              },
            ]}
          >
            <Input prefix="₹" />
          </Form.Item>
          <Form.Item
            label="Stock"
            name="stock"
            rules={[
              {
                required: true,
                message: 'Please input Stock!',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <div>Product Details:</div>
          <div className='border p-2'>
            <Form.Item
              label="Height"
              name="PD_height"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Width"
              name="PD_width"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="depth"
              name="PD_depth"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Weight"
              name="PD_weight"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Metal"
              name="PD_metal"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Additional details"
              name="PD_detail"
            >
              <Input.TextArea />
            </Form.Item>
          </div>
          <Form.Item
            name="is_active"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Active</Checkbox>
          </Form.Item>


          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button onClick={() => {  setIsAddModalOpen(false); form.resetFields();setEditId(null); setAddImage(null)  }}>
              Cancel
            </Button>
            <Button className='ms-3' type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Product view" width={900} open={isViewModalOpen} onCancel={() => setIsViewModalOpen(false)} footer={null} >
      {viewData?<>
        <div className='text-center'>
          <Image width='20%' src={viewData.idol_image} />
          <h3 className='p-2'>{viewData.idol_name }</h3>
          <div className='d-flex justify-content-center'>{renderTag(viewData.is_active,viewData)}</div>
        </div>
        <div className='row'>
            <div className='col-md-6 p-5'>
            <p><b>Price :</b> {viewData.price}</p>
            <p><b>Stock :</b> {viewData.stock }</p>
            <p><b>Category :</b> {viewData.cat_name}</p>
            </div>
            <div className='col-md-6 p-5'>
            <b>Description :</b> {viewData.idol_desc }
            </div>
        </div>
        </>:<><h2>Error...😢</h2></>}</Modal></div>
  )
}