import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import './styles.css'
import { NavLink, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import { getUser } from '../commonfn';
import main_logo from "../assets/main_logo.jpeg";

const Login = (props) => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log('Received values:', values);
    axiosInstance.post('/admin/login', values).then((res)=>{
      if(res.data.token){
        localStorage.setItem("token",`jwt ${res.data.token}`)
        navigate('/home')
        props.openLoginNotification(true)
      }
    }).catch((err)=>{
      console.log(err)
      props.openLoginNotification(false,err.response.data.message)
    })
  };

  return (
    <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh'}}>
    <div style={{width:'40%'}}>
      <Card className='shadow-lg' style={{backgroundColor:'#ccc', width:'100%', borderRadius:'20px'}}>
        <div className='d-flex justify-content-center'>
        <img src={main_logo} height={150} className="shadow-lg" style={{borderRadius:'20px'}}/>
        </div>
        <h1 className='login-header mt-3 mb-3'>Admin Login</h1>
    <Form
      name="login-form"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item
        label="Username "
        name="username"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input className='login-input-box'/>
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password className='login-input-box' />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className='login-btn'>
          Login
        </Button>
      </Form.Item>
    </Form>

    </Card>
    </div>
    </div>
  );
};

export default Login;
