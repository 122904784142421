import { Table, Tabs } from 'antd';
import React, { useState } from 'react'
import { IdolCat, IdolList } from './IdolTabs';

export default function IdolPage() {
  const TabItems = [
    {
      key: '1',
      label: 'Categories',
      children: <IdolCat/>,
    },
    {
      key: '2',
      label: 'Products',
      children: <IdolList/>,
    },
  ];
  return (
    <div><Tabs defaultActiveKey="1" items={TabItems}  /> </div>
  )
}
