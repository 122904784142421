import React, { useState } from 'react'
import { Button, Table, Tabs } from 'antd';
import { PoojasCat, PoojasServices } from './PoojasTabs';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

export default function PoojasPage() {
  const TabItems = [
    {
      key: '1',
      label: 'Categories',
      children: <PoojasCat/>,
    },
    {
      key: '2',
      label: 'Services',
      children: <PoojasServices/>,
    },
    
  ];
 
  
  return (
    <div><Tabs defaultActiveKey="1" items={TabItems}  />  </div>
  )
}
