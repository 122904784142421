import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../axiosInstance';
import { useNavigate } from 'react-router-dom';

export default function IdolOrders() {
  const [data, setData] = useState([])
  const navigate = useNavigate();

  useEffect(()=>{
    axiosInstance.get('/admin/get_order/all').then((res)=>{
      if (res.status == 208) {
        navigate('/login');
      }
      let orderData = res.data.data
      // Create an object to store orders grouped by order_id
const ordersByOrderId = {};

// Group orders by order_id
orderData.forEach(order => {
    const orderId = order.order_id;
    if (!ordersByOrderId[orderId]) {
        ordersByOrderId[orderId] = [];
    }
    ordersByOrderId[orderId].push(order);
});

// Convert the object of arrays into an array of arrays
const ordersArray = Object.values(ordersByOrderId);

console.log(ordersArray);
      // setData()
    }).catch((err)=>{console.log(err);alert('API Error 🤒')})
  },[])
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: 150,
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Action 2',
      width: 90,
      render: () => <a>action</a>,
    },
  ];
  return (
    <div><Table
    columns={columns}
    dataSource={data}
    pagination={{
      pageSize: 50,
    }}
    scroll={{
      y: 240,
    }}
  /></div>
  )
}
